import { FaTag } from "react-icons/fa/";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import List from "../components/List";
import {FormattedMessage} from 'react-intl';

const CategoryTemplate = props => {
  const {
    pageContext: { category },
    data: {
      allMarkdownRemark: { edges },
      site: {
        siteMetadata: { facebook }
      }
    },
    language
  } = props;

   const languageEdges = edges.filter(edge => {
    return (
    edge.node.frontmatter.language &&
     edge.node.frontmatter.language ==
      language.code)});
   const languageEdgesCount = languageEdges.length;
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline theme={theme}>
                <span><FormattedMessage
                    id="posts_in_catagory"/>
                    </span> <FaTag />
                {category}
              </Headline>
              <p className="meta">
                 {languageEdgesCount > 1 ? <FormattedMessage
                    id="posts_are"/> : <FormattedMessage
                    id="posts_is"/>} <strong>{languageEdgesCount}</strong>
                    {languageEdgesCount >
                1
                  ? <FormattedMessage
                  id="posts_s"/>
                  :  <FormattedMessage
                  id="posts_post"/>}{" "}
                  <FormattedMessage
                  id="in_the_category"/>
                
              </p>
              <List edges={languageEdges} theme={theme} />
            </header>
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} language={language}/>
    </React.Fragment>
  );
};

CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default CategoryTemplate;

// eslint-disable-next-line no-undef
export const categoryQuery = graphql`
  query PostsByCategory($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___prefix], order: DESC }
      filter: { fileAbsolutePath: { regex: "//posts//" }, frontmatter: { category: { eq: $category } } }
      ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            category
            language
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
